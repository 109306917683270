<template>
  <div id="app" class="h-full overflow-y-hidden">
    <router-view />
  </div>
</template>

<script>
import { get } from "vuex-pathify";
import forge from "node-forge";
import axios from "axios";
export default {
  name: "App",
  computed: {
    user: get("user"),
  },
  data() {
    return {
      publicKey: null,
      privateKey: null,
      machineid: null,
      flutter: null,
      location: null,
      device: null,
    };
  },
  async mounted() {
    console.log("APP MOUNTED");
    this.$store.set("isshowpopup", 1);
    this.flutter = this.getflutter();
    if (this.flutter) {
      await this.loadKeys();
      try {
        this.location = await this.flutter.callHandler("getlocation");
        if (this.location) {
          this.$store.set("location", this.location);
        }
      } catch (error) {
        console.error("elocation", JSON.stringify(error));
        console.error(error.stack);
      }

      try {
        this.device = await this.flutter.callHandler("getdevice");
        this.$store.set("device", this.device);
      } catch (e) {
        console.error("edevice", JSON.stringify(e));
      }

      try {
        const otpsignature = await this.flutter.callHandler("getotpsignature");
        this.$store.set("otpsignature", otpsignature);
      } catch (error) {
        console.error("e", JSON.stringify(error));
        console.error(error.stack);
      }
    }
    this.machineid = this.$store.get("machineid");
    if (!this.machineid) {
      try {
        if (this.flutter) {
          this.machineid = await this.flutter.callHandler(
            "getdata",
            "machineid"
          );
          if (!this.machineid) {
            await this.registerMachine();
          }
        }
      } catch (error) {
        console.error("eerror", JSON.stringify(error));
        console.error(error.stack);
      }
    }
  },
  methods: {
    closeNative() {
      console.log("CLOSENATIVE, GOODBYE SEE YOU LATER");
    },
    async loadKeys() {
      if (this.flutter) {
        try {
          this.privateKey = await this.flutter.callHandler(
            "readsecure",
            "privateKey"
          );
        } catch (error) {
          this.privateKey = await this.flutter.callHandler(
            "getdata",
            "privateKey"
          );
        }
        try {
          this.publicKey = await this.flutter.callHandler(
            "readsecure",
            "publicKey"
          );
        } catch (error) {
          this.publicKey = await this.flutter.callHandler(
            "getdata",
            "publicKey"
          );
        }
      }

      if (!this.privateKey || !this.publicKey) {
        try {
          await this.generateKeys();
        } catch (error) {
          console.error("genkeyerror", JSON.stringify(error));
          console.error(error.stack);
        }
      }
    },
    async generateKeys() {
      if (!this.publicKey || !this.privateKey) {
        const rsa = forge.pki.rsa;
        const keypair = rsa.generateKeyPair({ bits: 2048, e: 0x10001 });
        this.publicKey = forge.pki.publicKeyToPem(keypair.publicKey);
        this.privateKey = forge.pki.privateKeyToPem(keypair.privateKey);
        this.$store.set("publicKey", this.publicKey);
        this.$store.set("privateKey", this.privateKey);
        if (this.flutter) {
          const req = {};
          req["passkey"] = "-";
          if (this.user && this.user["userOtp"]) {
            req["passkey"] = this.user["userOtp"];
          }
          req["digitalSignature"] = "-";
          req["pubKey"] = this.publicKey;
          req["machineKey"] = this.getmachinekey(this.device);
          if (this.user) {
            req["userId"] = this.user["userid"] ?? 0;
            req["userName"] = this.user["username"] ?? "";
          }

          axios
            .post("/api/Users/RegisterRSA", req)
            .then((r) => {
              console.log("RegisterRSA", JSON.stringify(r));
            })
            .catch((e) => {
              console.error("RegisterRSA ERROR", JSON.stringify(e));
            });
          try {
            await this.flutter.callHandler(
              "writesecure",
              "publicKey",
              this.publicKey
            );
          } catch (error) {
            await this.flutter.callHandler(
              "setdata",
              "publicKey",
              this.publicKey
            );
          }

          try {
            await this.flutter.callHandler(
              "writesecure",
              "privateKey",
              this.privateKey
            );
          } catch (error) {
            await this.flutter.callHandler(
              "setdata",
              "privateKey",
              this.privateKey
            );
          }
        }
      }
    },
    async registerMachine() {
      const req = {
        machineId: this.machineid ? this.machineid : 0,
        status: 0,
        userId: 0,
      };
      req["machineName"] = this.device["devicename"];
      req["machineKey"] = this.getmachinekey(this.device);
      req["publicKey"] = this.publicKey;
      const r = await axios.post("/api/Users/registerMachine", req);
      if (!r["didError"]) {
        if (r["model"]) {
          this.machineid = r["model"]["machineId"];
          this.$store.set("machineid", this.machineid);
          await this.flutter.callHandler(
            "setdata",
            "machineid",
            `${this.machineid}`
          );
        }
      }
    },
  },
};
</script>

<style>
@font-face {
  font-family: "BoonBaan";
  src: url("../public/BoonBaan-Regular.ttf") format("truetype");
}
#app,
.swal2-popup,
.swal2-title,
.swal2-confirm,
.swal2-cancel {
  font-family: BoonBaan;
}

.modal {
  @apply fixed inset-0 bg-black bg-opacity-50 z-50;
}
.modal-background {
  @apply fixed bg-black bg-opacity-50 min-h-screen flex items-center justify-center inset-0 z-40;
}
</style>
