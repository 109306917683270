global.Buffer = global.Buffer || require("buffer").Buffer;

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import mixin from "./mixin";
import Axios from "axios";
import _ from "lodash";
import VueRouter from "vue-router";
import "./assets/tailwind.css";
import VueSweetalert2 from "vue-sweetalert2";

import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(VueSweetalert2);

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.mixin(mixin);
Object.defineProperty(Vue.prototype, "_", {
  value: _,
});

Axios.defaults.baseURL = process.env.VUE_APP_URLAPP;
Axios.interceptors.request.use(
  async (config) => {
    config.timeout = 10000;
    config.timeoutErrorMessage = "Time Out";
    const user = store.get("user");
    if (user) {
      if (!config.headers) {
        config.headers = [];
      }
      if (!config.headers["Authorization"] && user["access_token"]) {
        config.headers["Authorization"] = `Bearer ${user["access_token"]}`;
      }
      if (!config.headers["Content-type"]) {
        config.headers["Content-type"] = "application/json";
      }
    } else {
      try {
        let wait = 0;
        while (!window["flutter_inappwebview"]) {
          setTimeout(() => {}, 100);
          if (wait++ >= 100) {
            break;
          }
        }
        const flutter = window["flutter_inappwebview"];
        const res = await flutter.callHandler("getdata", "loginResponse");
        if (res && res["access_token"]) {
          store.set("user", res);
          config.headers["Authorization"] = `Bearer ${res["access_token"]}`;
        }
      } catch (e) {
        console.error(JSON.stringify(e));
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject("request", error);
  }
);

Axios.interceptors.response.use(
  (response) => {
    console.log("INTERCEPTORS RESPONSE", response);
    if (response.status == 200) {
      return Promise.resolve(response.data);
    } else {
      Promise.reject(response);
    }
  },
  async (error) => {
    if (
      error.message.includes("status code 400") ||
      error.message.includes("status code 401")
    ) {
      try {
        let wait = 0;
        while (!window["flutter_inappwebview"]) {
          setTimeout(() => {}, 100);
          if (wait++ >= 100) {
            break;
          }
        }
        if (window["flutter_inappwebview"]) {
          await window["flutter_inappwebview"].callHandler(
            "setdata",
            "loginResponse",
            null
          );
        }
      } catch (e) {
        console.error(JSON.stringify(e));
      }
      store.set("user", null);
      console.log(router.currentRoute.path);
      if (
        router.currentRoute.path !== "/" &&
        router.currentRoute.path !== "/login"
      ) {
        router.replace({ path: "/" });
      }
    }
    return Promise.reject(error);
  }
);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
