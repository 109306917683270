import dayjs from "dayjs";
import forge from "node-forge";

export default {
  data: function () {
    return {
      GROUPS: [
        { animal: "ປານ້ອຍ", numbers: ["01", "41", "81"] },
        { animal: "ຫອຍ", numbers: ["02", "42", "82"] },
        { animal: "ຫ່ານ", numbers: ["03", "43", "83"] },
        { animal: "ນົກຍຸງ", numbers: ["04", "44", "84"] },
        { animal: "ສິງ", numbers: ["05", "45", "85"] },
        { animal: "ເສືອ", numbers: ["06", "46", "86"] },
        { animal: "ໝູ", numbers: ["07", "47", "87"] },
        { animal: "ກະຕ່າຍ", numbers: ["08", "48", "88"] },
        { animal: "ຄວາຍ", numbers: ["09", "49", "89"] },
        { animal: "ນາກນໍ້າ", numbers: ["10", "50", "90"] },
        { animal: "ໝາ", numbers: ["11", "51", "91"] },
        { animal: "ມ້າ", numbers: ["12", "52", "92"] },
        { animal: "ຊ້າງ", numbers: ["13", "53", "93"] },
        { animal: "ແມວບ້ານ", numbers: ["14", "54", "94"] },
        { animal: "ໜູ", numbers: ["15", "55", "95"] },
        { animal: "ເຜິ້ງ", numbers: ["16", "56", "96"] },
        { animal: "ນົກຍາງ", numbers: ["17", "57", "97"] },
        { animal: "ແມວປ່າ", numbers: ["18", "58", "98"] },
        { animal: "ແມງກະເບື້ອ", numbers: ["19", "59", "99"] },
        { animal: "ຂີ້ເຂັບ", numbers: ["00", "20", "60"] },
        { animal: "ນົກແອ່ນ", numbers: ["21", "61"] },
        { animal: "ນົກກາງແກ", numbers: ["22", "62"] },
        { animal: "ລີງ", numbers: ["23", "63"] },
        { animal: "ກົບ", numbers: ["24", "64"] },
        { animal: "ແຫຼວ", numbers: ["25", "65"] },
        { animal: "ນາກບິນ", numbers: ["26", "66"] },
        { animal: "ເຕົ່າ", numbers: ["27", "67"] },
        { animal: "ໄກ່", numbers: ["28", "68"] },
        { animal: "ອ່ຽນ", numbers: ["29", "69"] },
        { animal: "ປາໃຫຍ່", numbers: ["30", "70"] },
        { animal: "ກຸ້ງ", numbers: ["31", "71"] },
        { animal: "ງູ", numbers: ["32", "72"] },
        { animal: "ແມງມຸມ", numbers: ["33", "73"] },
        { animal: "ກວາງ", numbers: ["34", "74"] },
        { animal: "ແບ້", numbers: ["35", "75"] },
        { animal: "ເຫງັນ", numbers: ["36", "76"] },
        { animal: "ຫຼິ່ນ", numbers: ["37", "77"] },
        { animal: "ເໝັ້ນ", numbers: ["38", "78"] },
        { animal: "ກະປູ", numbers: ["39", "79"] },
        { animal: "ນົກອິນຊີ", numbers: ["40", "80"] },
      ],
      GATEWAYS: [
        {
          code: "BCEL",
          name: "BCEL One",
          namelao: "ທະນາຄານການຄ້າຕ່າງປະເທດລາວ BCELOne",
          logo: "bcel.png",
          marketid: 8,
        },
        // {
        //   code: "APB",
        //   name: "APB Mepom",
        //   namelao: "ທ/ຄ ສົ່ງເສີມກະສິກຳ",
        //   logo: "apb.png",
        //   marketid: 22,
        // },
        {
          code: "LDB",
          name: "LDB Trust",
          namelao: "ທະນາຄານພັດທະນາລາວ LDB",
          logo: "ldb.png",
          marketid: 10,
        },
        {
          code: "JDB",
          name: "JDB Yes",
          namelao: "ທະນາຄານຮ່ວມພັດທະນາ JDB",
          logo: "jdb.png",
          marketid: 20,
        },
        // {
        //   code: "IB",
        //   name: "IB Cool",
        //   namelao: "ທ/ຄ ອິນໂດຈີນ (ໄວໆນີ້)",
        //   logo: "ib.png",
        //   marketid: 21,
        // },
        // {
        //   code: "POINT",
        //   name: "ຊໍາລະດ້ວຍຄະແນນ",
        //   namelao: "ຄະແນນຂອງທ່ານ",
        //   logo: "point.png",
        //   marketid: 1,
        // },
      ],
      PRODUCTS: [
        {
          name: "ໝາກອາງຸ່ນຂຽວ",
          price: 150000,
          photo:
            "https://img.freepik.com/free-photo/green-grape_1203-6914.jpg?t=st=1736578772~exp=1736582372~hmac=8f49dbf48a1aa1c212d79123de6da1ab77b5b92f71c92d040add6e536da904e6&w=1800",
        },
        {
          name: "ໝາກສີດາ",
          price: 60000,
          photo:
            "https://img.freepik.com/free-photo/guava-fruit_74190-2534.jpg?t=st=1736577458~exp=1736581058~hmac=78b1302aa4131b981cbdc506b59956e4900144d1abe02dc84dd860bf86e75e71&w=1480",
        },
        {
          name: "ໝາກອາໂວກາໂດ",
          price: 100000,
          photo:
            "https://img.freepik.com/free-photo/mix-ripped-green-avocados-white-wooden-board_482257-22893.jpg?t=st=1736578677~exp=1736582277~hmac=8f6093f8f2e592a18027cf16498ec7686fa807701a3ccdf85e6e9f4aa999ca20&w=1800",
        },
        {
          name: "ໝາກລິນຈີ່",
          price: 100000,
          photo:
            "https://img.freepik.com/free-photo/lychee-fruit-photography_1150-13684.jpg?t=st=1736577572~exp=1736581172~hmac=8fd6eb3514f27af15b158e1bfc5ec41b0e4bc19f9320fc2eec9a3e89c81d311e&w=1800",
        },
        {
          name: "ໝາກເງາະ",
          price: 80000,
          photo:
            "https://img.freepik.com/free-photo/pink-fruits-with-green-hairs_1122-1219.jpg?t=st=1736577640~exp=1736581240~hmac=05b06697a04742e2af45d07c4ec7fdaa4a82b81ad948d08286716de83aeb8f5f&w=1800",
        },
        {
          name: "ໝາກເດື່ອ",
          price: 150000,
          photo:
            "https://img.freepik.com/free-photo/green-fig-white_144627-24562.jpg?t=st=1736577675~exp=1736581275~hmac=5a8cb8e2c778b77097c03aed9c22f1f75a213e7bdc8cd549325ed74d41c1ef84&w=1380",
        },
        {
          name: "ໝາກຮຸ່ງ",
          price: 60000,
          photo:
            "https://img.freepik.com/free-photo/fresh-papaya-fruit_144627-34217.jpg?t=st=1736577730~exp=1736581330~hmac=edfc62c0a79671b2aa7ff22482656af6661bb2e6671989d38e023223e62c743a&w=1480",
        },
        {
          name: "ໝາກອາງຸ່ນດຳ",
          price: 150000,
          photo:
            "https://img.freepik.com/free-photo/grape-white-background_1323-248.jpg?t=st=1736579006~exp=1736582606~hmac=d4e3c1040864484282332f479f7956265d836bfca632acc97f68a486a13f60ea&w=1800",
        },
        {
          name: "ໝາກໂມ",
          price: 100000,
          photo:
            "https://img.freepik.com/free-photo/fresh-watermelon_1339-290.jpg?t=st=1736577903~exp=1736581503~hmac=2bedd901e3f3714ef7edaec8a1afdc65d6dc00197d9e2a718583f8c09013a51b&w=1800",
        },
        {
          name: "ໝາກມັງກອນ",
          price: 80000,
          photo:
            "https://img.freepik.com/free-photo/dragon-fruit_1203-8053.jpg?t=st=1736577974~exp=1736581574~hmac=a66df16bf973295c9bdc2476d059ad1146f9cf9f5c44f9ea5f66e2aed21239cf&w=1800",
        },
        {
          name: "ໝາກພ້າວ",
          price: 40000,
          photo:
            "https://img.freepik.com/free-photo/coconut-fruit_74190-2755.jpg?t=st=1736578129~exp=1736581729~hmac=315adaaf67275ef28fc59968ae2618c82a7c0c7c6cca2d1715dd3574d1a3e37a&w=1800",
        },
        {
          name: "ໝາກກ້ຽງ",
          price: 120000,
          photo:
            "https://img.freepik.com/free-photo/front-close-view-fresh-whole-oranges-juicy-sour-white-surface_140725-19918.jpg?t=st=1736578194~exp=1736581794~hmac=8e319ba3f282c455dee6ac511fe3c7b4285a195bc00ceac39224292262db79d6&w=1800",
        },
      ],
    };
  },
  filters: {
    formatnumber(x) {
      if (!x) return 0;
      if (x) {
        return (x % 1 != 0 ? x.toFixed(2) : x)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return x;
    },
    displaydigit(x, ishide) {
      if (!x) return "";
      if (x.length === 1) x = `0${x}`;
      return (ishide ? "******" : x).substring(0, x.length);
    },
    datetime(x) {
      if (x) {
        return dayjs(x).format("DD/MM/YYYY HH:mm:ss");
      }
      return x;
    },
    date(x) {
      if (x) {
        return dayjs(x).format("DD/MM/YYYY");
      }
      return x;
    },
    time(x) {
      if (x) {
        return dayjs(x).format("HH:mm:ss");
      }
      return x;
    },
  },
  methods: {
    displaydigit(x, ishide) {
      if (!x) return "";
      return (ishide ? "******" : x).substring(0, x.length);
    },
    getAnimalImage(num) {
      if (!num || num === "") return "";
      if (num.length === 1) num = `0${num}`;
      return `animals/${this.getAnimalName(num)}.png`;
    },
    getAnimalName(num) {
      let num2 = num;
      if (num2.length >= 2) {
        num2 = num2.slice(-2);
      } else {
        num2 = "0" + num2;
      }

      const match = this._.find(this.GROUPS, (g) => {
        return g.numbers.includes(num2);
      });
      return match ? match.animal : "";
    },
    formatnumber(x) {
      if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return "";
    },
    datetime(x) {
      if (x) {
        return dayjs(x).format("DD/MM/YYYY HH:mm:ss");
      }
      return x;
    },
    date(x) {
      if (x) {
        return dayjs(x).format("DD/MM/YYYY");
      }
      return x;
    },
    async sign(data, privateKeyPem, flutter) {
      if (!privateKeyPem) {
        try {
          privateKeyPem = await flutter.callHandler("readsecure", "privateKey");
        } catch (error) {
          console.error("ERROR", JSON.stringify(error));
          console.error(error.stack);
          privateKeyPem = await flutter.callHandler("getdata", "privateKey");
        }
      }

      let publicKeyPem = null;
      try {
        publicKeyPem = await flutter.callHandler("readsecure", "publicKey");
      } catch (error) {
        publicKeyPem = await flutter.callHandler("getdata", "publicKey");
      }

      try {
        const privateKey = forge.pki.privateKeyFromPem(privateKeyPem);
        const md = forge.md.sha512.create();
        md.update(data, "utf8");
        const signature = forge.util.encode64(privateKey.sign(md));
        const v = await this.verifySignature(
          data,
          signature,
          publicKeyPem,
          flutter
        );
        console.log("veri", v);
        return signature;
      } catch (error) {
        console.error(JSON.stringify(error));
        console.error(error.stack);
        return null;
      }
    },
    async verifySignature(data, signatureBase64, publicKeyPem, flutter) {
      if (!publicKeyPem) {
        try {
          publicKeyPem = await flutter.callHandler("readsecure", "publicKey");
        } catch (error) {
          publicKeyPem = await flutter.callHandler("getdata", "publicKey");
        }
      }
      try {
        const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
        const md = forge.md.sha512.create();
        md.update(data, "utf8");
        const signature = forge.util.decode64(signatureBase64);
        return publicKey.verify(md.digest().bytes(), signature);
      } catch (error) {
        console.error(JSON.stringify(error));
        console.error(error.stack);
        return false;
      }
    },
    generateRandom(characters, length) {
      let result = "";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    randomCharacter(length) {
      return this.generateRandom(
        `ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789`,
        length
      );
    },
    randomNumber(length) {
      return this.generateRandom(`0123456789`, length);
    },
    getflutter() {
      let wait = 0;
      while (!window["flutter_inappwebview"]) {
        setTimeout(() => {}, 100);
        if (wait++ >= 100) {
          break;
        }
      }
      return window["flutter_inappwebview"];
    },
    getmachinekey(device) {
      if (!device) return "";
      let output = "";
      if (device["devicetype"]) {
        output += device["devicetype"];
      }
      if (device["devicename"]) {
        output += device["devicename"];
      }
      if (device["deviceversion"]) {
        output += device["deviceversion"];
      }
      if (device["deviceidentifier"]) {
        if (
          !device["deviceidentifier"] ||
          device["deviceidentifier"] === "" ||
          device["deviceidentifier"] === "unknown" ||
          device["deviceidentifier"] === "UNKNOWN"
        ) {
          device["deviceidentifier"] =
            device["firebasetokenid"] ??
            device["huaweitoken"] ??
            this.randomCharacter(10);
        }
        output += device["deviceidentifier"];
      }
      if (device["all"]) {
        try {
          const all = JSON.parse(device["all"]);
          if (all["androidId"]) {
            if (all["androidId"] === "unknown") {
              all["androidId"] =
                device["firebasetokenid"] ?? this.randomCharacter(10);
            }
            output += all["androidId"];
          }
        } catch (error) {
          console.error(error);
        }
      }
      if (window["flutter_inappwebview"]) {
        window["flutter_inappwebview"].callHandler(
          "setdata",
          "machinekey",
          output
        );
      }
      return output;
    },
    isnumber(str) {
      return /^\d+$/.test(str);
    },
  },
};
